<!-- eslint-disable -->
<template>
  <base-material-card
      icon="mdi-clipboard-text"
      title="Voir la liste des demandes SOS"
      class="px-5 py-3"
    >
    <template v-slot:heading>
      <v-icon @click="reloadItems">mdi-reload</v-icon>
    </template>
    <!-- <v-row>
    <v-col cols="12" sm="2">
      <base-material-stats-card
            color="primary"
            icon="mdi-package-variant-closed"
            title="Arrivé"
            :value="delivered.toString()"
        />
    </v-col>
    <v-col cols="12" sm="2">
      <base-material-stats-card
          color="info"
          icon="mdi-truck-delivery"
          title="Dépanné"
          :value="engoing.toString()"
        />
    </v-col>
    <v-col cols="12" sm="2">
      <base-material-stats-card
          color="orange"
          icon="mdi-motion-pause-outline"
          title="Démarré"
          :value="enhold.toString()"
        />
    </v-col>
    <v-col cols="12" sm="2">
      <base-material-stats-card
          color="gray"
          icon="mdi-briefcase-check-outline"
          title="Created"
          :value="created.toString()"
        />
    </v-col>
    <v-col cols="12" sm="2">
      <base-material-stats-card
          color="red"
          icon="mdi-message-alert"
          title="Returned"
          :value="returned.toString()"
        />
    </v-col>
    </v-row> -->
    <b-modal v-model="showMapModal" hide-footer size="lg">
      <template #modal-title>
        Afficher dans la carte
      </template>
      <div id="map" style="height:400px; width:100%;"></div>
    </b-modal>
    <b-modal v-model="show2" @ok="getpaid()" title='CONFIRMATION DE PAIEMENT'>
      <!-- <h3 style="color:green">CONFIRMATION DE PAIEMENT</h3> -->
      <br><v-icon style="color:red">mdi-alert</v-icon>Une fois cliqué OK, vous ne pouvez pas faire l'opération inverse<br><br>
      <p style="color:green"><v-icon style="color:green">mdi-check-box-multiple-outline</v-icon> OK pour confirmer le paiement</p>

    </b-modal>
    <b-modal v-model="show3" @ok="deletePackage" title='CONFIRMATION DE SUPPRESSION'>
      <!-- <h3 style="color:green">CONFIRMATION DE PAIEMENT</h3> -->
      <br><v-icon style="color:red">mdi-alert</v-icon>Une fois cliqué OK, vous ne pouvez pas faire l'opération inverse<br><br>
      <p style="color:green"><v-icon style="color:green">mdi-check-box-multiple-outline</v-icon> OK pour confirmer la suppression</p>
    </b-modal>
    <!-- <b-modal v-model="show">
      <h3>{{infoshow.tracking_id}} :{{infoshow.content}}</h3><p>Clients name : {{infoshow.client_name}}</p><p>Phone Number : {{infoshow.client_phone}}</p>
      <p v-for="histori in infoshow.history" :key="histori.message">***{{moment(histori.creation_date).format('MM/DD/YYYY HH:mm')}} : {{histori.message}}</p>
    </b-modal> -->
    <!-- <b-modal v-model="show4" @hidden="handleModalHide" hide-footer size="xl">
      <template #modal-title>
        Détail de Colis <b-badge variant="success">{{packageDetail.tracking_id}}</b-badge>
      </template>
        <div style="text-align:center;">
          <v-btn class="mr-3" style="color:white;background-color:#FBC02D" @click="makeChanges2('Accepted')">Accepted</v-btn>
          <v-btn class="mr-3" style="color:white;background-color:#8E24AA" @click="makeChanges2('Dépanné')">Dépanné</v-btn>
          <v-btn class="mr-3" style="color:white;background-color:#0096FF" @click="makeChanges2('Démarré')">Démarré</v-btn>
          <v-btn class="mr-3" style="color:white;background-color:green" @click="makeChanges2('Arrivé')">Arrivé</v-btn>
        </div>
      <hr>
    </b-modal> -->
    <b-modal v-model="show4" @hidden="handleModalHide" hide-footer size="xl">
    <template #modal-title>
      Détail de Colis <b-badge variant="success">{{packageDetail.tracking_id}}</b-badge>
    </template>
      <div style="text-align:center;">
        <v-btn style="color:white;background-color:#616161" @click="makeChanges2('Created')">Created</v-btn>
        <v-btn style="color:white;background-color:#FBC02D" @click="makeChanges2('Accepted')">Accepted</v-btn>
        <v-btn style="color:white;background-color:#0096FF" @click="makeChanges2('Démarré')">Démarré</v-btn>
        <v-btn style="color:white;background-color:#8E24AA" @click="makeChanges2('Dépanné')">Dépanné</v-btn>
        <v-btn style="color:white;background-color:green"   @click="makeChanges2('Arrivé')">Arrivé</v-btn>
      </div>
    <hr>
    <b-form >
      <!-- @submit.prevent="makeChanges" -->
      <div class="d-block text-center" style="background-color:white">
        <div class="form-group" v-if="packageDetail != null && packageDetail != undefined">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="packageDetail.client_name" label="Nom de client" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="packageDetail.client_phone" label="N° Téléphone" class="purple-input"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <label>Wilaya de départ</label>
                <b-form-select required v-model="packageDetail.wilaya1" @change="loadCommunues(1)"><option :value="wilaya.name" v-for="wilaya in willayas" :key="wilaya.name">{{wilaya.name}}</option> </b-form-select>
              </v-col>
              <v-col cols="12" md="4">
                <label>Communue de départ</label>
                <b-form-select required v-model="packageDetail.communue1"><option :value="communue.name" v-for="communue in packageDetail.communues1" :key="communue.name">{{communue.name}}</option> </b-form-select>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field required label="Adresse de départ" class="purple-input" v-model="packageDetail.address1"/>
              </v-col>
              </v-row>
              <v-row>
              <v-col cols="12" md="4">
                <label>Wilaya d'arrivé</label>
                <b-form-select required v-model="packageDetail.wilaya2" @change="loadCommunues(2)"><option :value="wilaya.name" v-for="wilaya in willayas" :key="wilaya.name">{{wilaya.name}}</option> </b-form-select>
              </v-col>
              <v-col cols="12" md="4">
                <label>Communue d'arrivé</label>
                <b-form-select required v-model="packageDetail.communue2"><option :value="communue.name" v-for="communue in packageDetail.communues2" :key="communue.name">{{communue.name}}</option> </b-form-select>
              </v-col>
                <v-text-field required label="Adresse de départ" class="purple-input" v-model="packageDetail.address2"/>
              <v-col cols="12" md="4">
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <label>Chauffeur</label>
                <multiselect required v-model="packageDetail.selected_driver" tag-placeholder="Chauffeur" placeholder="Entrer le nom de chauffeur" label="name" track-by="name" :options="drivers" :multiple="false" :taggable="true" @input="detectDriver"></multiselect>
                <!-- <b-form-select required v-model="packageDetail.selected_driver">
                  <option v-for="driver in drivers" :key="driver.did" :value="driver.did" :class="(driver.available===true || packageDetail.selected_driver===driver.did ? 'color-green' : (driver.available === false ? 'color-red': ''))">
                    <span v-if="driver.available==false">{{driver.name}} {{driver.phone}}</span>
                    <span v-else>{{driver.name}} {{driver.phone}}</span>
                  </option>
                </b-form-select> -->
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field required v-model="packageDetail.service_price" label="Prix de livraison" type="number" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field required v-model="packageDetail.driver_price" label="Frais de livraison" type="number" class="purple-input"/>
              </v-col>
              <v-col v-if="packageDetail.selected_driver.did==-1" cols="12" md="4">
                <v-text-field required label="Nom de Chauffeur" class="purple-input" v-model="packageDetail.driver_name"/>
              </v-col>
              <v-col v-if="packageDetail.selected_driver.did==-1" cols="12" md="4">
                <v-text-field required label="N° Téléphone Chauffeur" class="purple-input" v-model="packageDetail.driver_phone"/>
              </v-col>
              <v-col v-if="packageDetail.selected_driver.did==-1" cols="12" md="4">
                <v-text-field required label="Matricule Chauffeur" class="purple-input" v-model="packageDetail.driver_matricule"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field required label="Type de Véhicule" class="purple-input" v-model="packageDetail.vehicule"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field required label="Matricule" class="purple-input" v-model="packageDetail.matricule"/>
              </v-col>
            </v-row>
            <v-col cols="12">
              <v-textarea
                required
                class="purple-input"
                label="Plus de détails (Max 1000 caractères, sinon laisser vide)"
                v-model="packageDetail.description"
              />
            </v-col>
        </div>
      </div>
    </b-form>
  </b-modal>
    <b-modal v-model="show" @hidden="handleModalHide" hide-footer size="xl">
    <template #modal-title>
      Détail de demande <b-badge variant="success">{{packageDetail.tracking_id}}</b-badge>
    </template>
    <div class="d-block text-center" style="background-color:white">
      <div class="form-group" v-if="packageDetail != null && packageDetail != undefined">
        <h4>Section des dates</h4>
        <strong>Date de création: </strong>{{serialiseDate(packageDetail.creation_date)}}<br>
        <strong>Date d'expédition: </strong>{{serialiseDate(packageDetail.shipping_date)}}<br>
        <strong>Date de livraison: </strong>{{serialiseDate(packageDetail.delivery_date)}}<br>
        <hr>
          <h4>Section d'historique</h4>
          <p v-for="histori in infoshow.history" :key="histori.message"><strong>{{moment(histori.creation_date).format('DD/MM/YYYY HH:mm')}}: </strong> {{histori.message}}</p>
        <hr>
        <h4>Section d'infomation de client</h4>
        <strong>Nom complet de client: </strong>{{packageDetail.client_name}}<br>
        <strong>N° Téléphone de client: </strong>{{packageDetail.client_phone}}<br>
        <strong>Adresse de départ: </strong>{{packageDetail.wilaya1}}, {{packageDetail.communue1}}, {{packageDetail.address1}}<v-icon @click="showLocation('addr1')" id="depart-icon" style="color:green">mdi-flag-triangle</v-icon><br>
        <strong>Adresse d'arrivé: </strong>{{packageDetail.wilaya2}}, {{packageDetail.communue2}}, {{packageDetail.address2}}<v-icon @click="showLocation('addr2')" id="arrive-icon" style="color:red">mdi-flag-checkered</v-icon><br>
        <strong>Description: </strong>{{packageDetail.description}}<br>
        <strong>Vehicule: </strong>{{packageDetail.vehicule}}<br>
        <strong>Matricule: </strong>{{parseInt(packageDetail.matricule)}}<br>
      </div>
    </div>
    <b-button class="mt-3" style="color:white" block @click="hideModal">Fermer</b-button>
  </b-modal>
  <b-modal v-model="showChangable" @hidden="handleModalHide" hide-footer size="xl">
    <template #modal-title>
      Détail de Colis <b-badge variant="success">{{packageDetail.tracking_id}}</b-badge>
    </template>
    <b-form @submit.prevent="makeChanges">
      <div class="d-block text-center" style="background-color:white">
        <div class="form-group" v-if="packageDetail != null && packageDetail != undefined">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field required v-model="packageDetail.client_name" label="Nom de client" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field required v-model="packageDetail.client_phone" label="N° Téléphone" class="purple-input"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field required v-model="packageDetail.price" label="Prix de colis" type="number" class="purple-input"/>
              </v-col>
            </v-row>
        </div>
      </div>
      <b-button class="mr-3 float-right" style="color:white;" @click="hideModal">Annuler</b-button>
      <b-button class="mr-3 float-right" style="color:white;" variant="success" type="submit">Confirmer</b-button>
    </b-form>
  </b-modal>
    <!-- <v-simple-table>
      <thead>
          <tr>
            <th class="primary--text">
              <strong>ID</strong>
            </th>
            <th class="primary--text">
              Produit
            </th>
            <th class="primary--text">
              Client
            </th>
            <th class="primary--text">
              Status
            </th>
            <th class="primary--text">
              Post
            </th>
            <th class="text-right primary--text">
              Prix
            </th>

          </tr>
        </thead>
        <tbody>
          <tr v-for="pakage in packages" :key="pakage.package_id">
            <td v-if="(pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' && wilaya_search == 'All')"><v-btn depressed small color="#37474F">{{pakage.package_id}}</v-btn></td>
            <td v-if="(pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' && wilaya_search == 'All')">{{pakage.content}}</td>
            <td v-if="(pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' && wilaya_search == 'All')">{{pakage.client_name}}</td>
            <td v-if="((pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' && wilaya_search == 'All')) && pakage.status == 'Arrivé'"><v-btn depressed small color="green">{{pakage.status}}</v-btn></td>
            <td v-if="((pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' && wilaya_search == 'All')) && pakage.status == 'Created'"><v-btn depressed small color="blue">{{pakage.status}}</v-btn></td>
            <td v-if="(pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' && wilaya_search == 'All')">{{pakage.wilaya}}</td>
            <td v-if="(pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' &&  wilaya_search == 'All')" class="text-right">
              {{pakage.price}}
            </td>
          </tr>

        </tbody>
    </v-simple-table> -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :isLoading.sync="isLoading"
      @on-column-filter="filtering"
      :pagination-options="{enabled: true, perPageDropdown:[10, 20,50,100,200],perPage:15}"
      @on-row-click="RowClick">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'status'">
          <v-btn id="status-btn" v-if="props.row.status == 'Created'" depressed small color="#616161">{{props.row.status}}</v-btn>
          <v-btn id="status-btn" v-if="props.row.status == 'Accepted'" depressed small color="#FBC02D">{{props.row.status}}</v-btn>
          <v-btn id="status-btn" v-if="props.row.status == 'Démarré'" depressed small color="#0096FF">{{props.row.status}}</v-btn>
          <v-btn id="status-btn" v-if="props.row.status == 'Dépanné'" depressed small color="#8E24AA">{{props.row.status}}</v-btn>
          <v-btn id="status-btn" v-if="props.row.status == 'Arrivé'" depressed small color="green">{{props.row.status}}</v-btn>
          <!-- <v-btn v-if="props.row.status == 'Arrived'" depressed small color="blue">{{props.row.status}}</v-btn>
          <v-btn v-if="props.row.status == 'Returned-ONGO'" depressed small color="#D50000">{{props.row.status}}</v-btn>
          <v-btn v-if="props.row.status == 'Returned-DLI'" depressed small color="#D50000">{{props.row.status}}</v-btn>
          <v-btn v-if="props.row.status == 'Returned-ARV'" depressed small color="#D50000">{{props.row.status}}</v-btn>
          <v-btn id="returned-btn" v-if="props.row.status == 'Returned'" depressed small color="#D50000">{{props.row.status}}</v-btn> -->
          <v-icon  v-if="props.row.status == 'Created'" id="history-btn" style="margin-left:10px;color:red">mdi-close-box</v-icon>
        </span>
        <span v-else-if="props.column.field == 'tracking_id'">
          <v-icon id='print-btn' style="margin-right:10px" color="primary">mdi-printer</v-icon>
          <v-btn id="tracking-id-btn" v-b-tooltip.hover :title="copyMessage" depressed small color="#37474F">{{props.row.tracking_id}}</v-btn>
          <v-icon v-b-tooltip.hover title="Livraison à domicile" v-if="props.row.delivery_type=='Home'" style="margin-left:10px;margin-right:10px;" color="primary">mdi-home-map-marker</v-icon>
          <v-icon v-b-tooltip.hover title="Livraison gratuit" v-if="props.row.free_delivered==true" style="margin-left:10px;margin-right:10px;" color="primary">mdi-currency-usd-off</v-icon>
          <!-- <v-icon v-if="props.row.delivery_type=='Stop Desk'" style="margin-left:10px;margin-right:10px;" color="primary">mdi-home-map-marker</v-icon> -->
          <v-btn id="pay-btn" v-if="props.row.status == 'Arrivé' && props.row.posttoprovider == false && props.row.free_delivered == false && props.row.price != 0" class="float-right" depressed small color="red">NOT PAID</v-btn>
          <v-btn v-if="props.row.status == 'Arrivé' && props.row.posttoprovider == true && props.row.free_delivered == false && props.row.price != 0" depressed small color="green" class="float-right">GET PAID</v-btn>
          <!-- <v-btn v-if="props.row. == 'Arrivé' && props.row.posttoprovider == true" depressed small color="green">GET PAID</v-btn> -->
        </span>
        <span v-else-if="props.column.field == 'location'">
          <v-icon id="depart-icon" style="color:green">mdi-flag-triangle</v-icon>
          <v-icon>mdi-arrow-right-bold-outline</v-icon>
          <v-icon id="arrive-icon" style="color:red">mdi-flag-checkered</v-icon>
        </span>
        <span v-else>{{props.row[props.column.field]}}</span>
      </template>
    </vue-good-table>
  </base-material-card>

</template>

<script>
/* eslint-disable */
import axios from 'axios'
import moment from 'moment'
export default {
  name: 'Dashboard',
  data () {
    return {
      // searchTerm: '',
      showMapModal:false,
      moment: moment,
      copyMessage: 'Cliquer pour copier',
      provider: null,
      pemail: null,
      fuckoff: null,
      posts: [],
      welcoming: null,
      packages: [],
      status_list: ['Created', 'Arrivé','All'],
      wilaya_list: ['Alger', 'Annaba', 'Bejaia','All','Mascara'],
      produit_list: ['Phone', 'Ibad', 'Laptop','All'],
      status_search: 'All',
      wilaya_search: 'All',
      produit_search: 'All',
      search: null,
      packageDetail: {provider:{},selected_driver:{}},
      columns: [
        {
          label:'Tracking_Id',
          field:'tracking_id',
          filterOptions: {enabled:true,placeholder: 'Entrer le tracking',}
        },
        {
          label:'Nom de client',
          field:'client_name',
          filterOptions: {enabled:true,placeholder: 'Entrer Produit',}
        },
        {
          label:'N° Tél ',
          field:'client_phone',
          filterOptions: {enabled:true,placeholder: 'Entrer le Client',}
        },
        {
          label:'Status',
          field:'status',
          filterOptions: {enabled:true,placeholder: 'Choisir le status',filterDropdownItems:['Created','Arrivé','Dépanné','Arrived','Démarré','Returned','Returned-ARV','Returned-DLI','Returned-ONGO']}
        },
        {
          label:'Wilaya',
          field:'wilaya1',
          filterOptions: {enabled:true,placeholder: 'Entrer la wilaya'}
        },
        {
          label:'Location',
          width: '120px',
          field:'location'
        }
      ],
      rows: [],
      willayas: [],
      show: false,
      show4:false,
      showChangable: false,
      infoshow: [],
      show2 : false,
      show3: false,
      tr_id2: null,
      delivered: null,
      returned: null,
      created: null,
      engoing: null,
      enhold: null,
      setset: null,
      isLoading: false,
      drivers: [],
      lat:null,
      long:null,
    }

  },
  mounted() {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
    this.gettingwilayas()
    this.getingpackages()
    this.loadDrivers()
  },
  methods: {
    loadCommunues(choice) {
      if (choice == 1) {
        for (let i in this.willayas) {
          if (this.willayas[i].name == this.packageDetail.wilaya1) {
            this.packageDetail.communues1 = this.willayas[i].communues
            break
          }
        }
      } else if (choice == 2) {
        for (let i in this.willayas) {
          if (this.willayas[i].name == this.packageDetail.wilaya2) {
            this.packageDetail.communues2 = this.willayas[i].communues
            break
          }
        }
      }
    },
    detectDriver() {
      if (this.packageDetail.selected_driver.did == -1) {

      }
    },
    showLocation(addr) {
      var _this = this;
      function initMap() {
        const uluru = { lat: _this.lat, lng: _this.long };
        const map = new google.maps.Map(document.getElementById("map"), {
          zoom: 17,
          center: uluru,
        });
        const marker = new google.maps.Marker({
          position: uluru,
          map: map,
        });
      }
      this.showMapModal = true
      setTimeout(function() {
        window.initMap = initMap();
      },200);
      if (addr == 'addr1') {
        this.lat = this.packageDetail.lat1
        this.long = this.packageDetail.long1
      } else if (addr == 'addr2') {
        this.lat = this.packageDetail.lat2
        this.long = this.packageDetail.long2
      }
    },
    loadDrivers() {
      axios.post('admin-drivers', {
        auth_token: localStorage.getItem('auth_token'),
      }).then(response => {
        if (response.data.status == 'success') {
          this.drivers = [];
          for (let i = 0; i < response.data.data.length; i++) {
            if (response.data.data[i].approved == true) {
              response.data.data[i].id = response.data.data[i].did;
              if (response.data.data[i].service_type == "Service SOS (Auto Dépannage)") this.drivers.push(response.data.data[i])
            }
          }
          this.drivers.push({
            name:'Autre',
            code:'other',
            did: -1,
            available:null
          })
        }
      })
    },
    makeChanges2(status) {
      axios.post('change-sos-request',{
        auth_token: localStorage.getItem('auth_token'),
        tr_id: this.packageDetail.tracking_id,
        client_name:this.packageDetail.client_name,
        client_phone:this.packageDetail.client_phone,
        driver_name: this.packageDetail.driver_name,
        driver_phone: this.packageDetail.driver_phone,
        driver_matricule: this.packageDetail.driver_matricule,
        selected_driver:this.packageDetail.selected_driver,
        description:this.packageDetail.description,
        driver_price:this.packageDetail.driver_price,
        service_price:this.packageDetail.service_price,
        wilaya1:this.packageDetail.wilaya1,
        wilaya2:this.packageDetail.wilaya2,
        communue1:this.packageDetail.communue1,
        communue2:this.packageDetail.communue2,
        address1:this.packageDetail.address1,
        address2:this.packageDetail.address2,
        matricule:this.packageDetail.matricule,
        vehicule:this.packageDetail.vehicule,
        status:status,
      }).then(response => {
        if (response.data.status == 'success')
          this.getingpackages()
          this.show4 = false
      })
    },
    reloadItems() {
      this.getingpackages()
      this.isLoading = true
    },
    getPostCenters() {
        axios.post('get-post-centers').then(response => {
          if (response.data.status == 'success') {
            let names= []
            response.data.data.forEach(element=> {
              names.push(element.name)
            })
            this.$set(this.columns[4].filterOptions, 'filterDropdownItems', names);
          }
        })
      },
    getpaid(){
      axios.post('getpaid',{
        auth_token: localStorage.getItem('auth_token'),
        tr_id: this.tr_id2,
        setset: this.setset,
      }).then(response => {
        this.getingpackages()
      })

    },
    copyTrackingId(row) {
      this.$clipboard(row.tracking_id);
      this.copyMessage = 'Copié!'
      setTimeout(() => {
        this.copyMessage = 'Cliquer pour copier'
      }, 2000);
    },
    deletePackage() {
      axios.post('deletecolis',{
          auth_token: localStorage.getItem('auth_token'),
          tr_id: this.packageDetail.tracking_id,
        }).then(response => {
          this.getingpackages()
        })
    },
    makeChanges() {
      axios.post('provider-change-package',{
        auth_token: localStorage.getItem('auth_token'),
        tr_id: this.packageDetail.tracking_id,
        new_price: this.packageDetail.price,
        client_name: this.packageDetail.client_name,
        client_phone: this.packageDetail.client_phone
      }).then(response => {
        if (response.data.status == 'success')
          this.getingpackages()
          this.showChangable = false
      })
    },
    RowClick(params){
      this.packageDetail = params.row
      for (let i in this.drivers) {
        if (params.row.selected_driver == this.drivers[i].id) {
          this.packageDetail.selected_driver = this.drivers[i];
          break
        }
      }
      this.loadCommunues(1)
      this.loadCommunues(2)
      if (params.event.target.id == "history-btn"){
        this.show3 = true
      }
      else if (params.event.target.id == "status-btn" || params.event.target.parentNode.id == 'status-btn'){
        this.show4 = true
      }
      else if (params.event.target.id == 'print-btn' || params.event.target.parentNode.id == 'print-btn') {
        axios.post('print-pdf-express', {
          auth_token: localStorage.getItem('auth_token'),
          tracking_id: this.packageDetail.tracking_id
        }).then(response=> {
          if (response.data.status == 'success') {
            const link = document.createElement('a')
            link.target = '_blank'
            link.href = process.env.VUE_APP_MEDIA_URL+"document.pdf"
            link.setAttribute('download', "document.pdf")
            document.body.appendChild(link)
            link.click()
          }
        })
      }
      else if (params.event.target.id == 'arrive-icon') {
        this.showLocation('addr2')
      }
      else if (params.event.target.id == 'depart-icon') {
        this.showLocation('addr1')
      }
      else if (params.event.target.id == 'changable-btn') {
        this.showChangable = true
      }
      else if (params.event.target.id == 'tracking-id-btn' || params.event.target.parentNode.id == 'tracking-id-btn') {
        this.copyTrackingId(params.row)
      }
      else if (params.event.target.id == "pay-btn" || params.event.target.parentNode.id == 'pay-btn'){
        this.show2 = true
        this.tr_id2 = params.row.tracking_id
        this.setset = 'normal'
      }
      else{
        this.show = true
        this.infoshow = params.row
      }
    },
    // gettingwilayas(){
    //   axios.post('get-wilayas').then(response => {

    //     for (let i=0;i<response.data.data.length;i++){
    //       this.willayas.push(response.data.data[i].name)
    //     }
    //     // this.columns[4].filterOptions.filterDropdownItems= this.willayas
    //     this.$set(this.columns[4].filterOptions, 'filterDropdownItems', this.willayas);
    //   })
    gettingwilayas(){
      axios.post('get-wilayas').then(response => {
        this.willayas = response.data.data
        this.$set(this.columns[5].filterOptions, 'filterDropdownItems', this.willayas);
        this.$set(this.columns[4].filterOptions, 'filterDropdownItems', ['Created','Accepted']);
      })

    },
    filtering(params) {
      console.log(params)
    },
    getStatusList() {
      axios.post('get-status-list').then(response => {
        if (response.data.status == 'success') {
          this.items = response.data.data
        }
      })
    },
    handleModalHide(params) {
      this.show = false
      this.showChangable = false
    },
    hideModal(params) {
      this.show = false
      this.showChangable = false
    },
    serialiseDate(date) {
      if (date != null && date!= undefined)
        return moment(date).format('DD/MM/YYYY HH:mm')
      else
        return 'N/A'
    },
   detectingprovider(){
        axios.post('detectingprovider',{
          auth_token: localStorage.getItem('auth_token'),
        }).then(response => {
          this.provider = response.data.data.provider.name
          this.pemail = response.data.data.provider.email
          this.fuckoff = response.data.status
          for (let i=0;i<response.data.data.posts.length;i++){
            this.posts.push({code:response.data.data.posts[i].code,name:response.data.data.posts[i].name})
          }
        })
      },
    getingpackages(){
      axios.post('get-sos-requests',{
        auth_token: localStorage.getItem('auth_token'),
      }).then(response => {
        this.rows=response.data.data
      })
    }
  },
}
</script>
<style>

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>